import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './utils/vant'
import './permission'

// 加载 element 组件库
import ElementUI from 'element-ui'

// 加载 element 组件库的样式
import 'element-ui/lib/theme-chalk/index.css'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
// 全局挂载
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)
// 全局注册 element 组件库
Vue.use(ElementUI)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
